<template>
  <section>
    <section class="es-loss-material">
      <VanSticky>
        <div class="page-head xa-cell">
          <Van-Button
            v-if="isApp"
            size="small"
            class="xa-txt-blue"
            @click="toScanMaterial"
            >扫码录入</Van-Button
          >
          <Van-Button
            size="small"
            class="xa-txt-blue"
            @click="showInputPopup = true"
            >手动输入</Van-Button
          >
        </div>
      </VanSticky>
      <MaterialEditList
        :items="list"
        :result="resultsMap"
        @sndelete="onSnDelete"
      />
      <EnsureFootBtn
        buttonText="提交生成报价单"
        :disabled="false"
        @submit="onSubmit"
      />
    </section>
    <van-popup v-model="showInputPopup" position="top">
      <div>
        <div class="xa-cell__box xa-txt-blue">手动录入序列号</div>
        <van-field
          v-model="curSn"
          placeholder="可通过换行或空格，输入多个"
          type="textarea"
          autosize
        >
          <van-button
            :disabled="!curSn"
            slot="button"
            size="small"
            type="info"
            @click="onSubmitSn"
            >确定</van-button
          >
        </van-field>
      </div>
    </van-popup>
  </section>
</template>
<script>
import { Button, Field, Sticky, Popup } from 'vant'
import basePage from '@/mixins/basePage'
import MaterialEditList from '@/components/material/MaterialEditList-InputGoodPart'
import {
  checkAuthCode,
  getInputGoodMaterialInfoList,
  editSingleMaterial,
  editBatchMaterial,
  submitInputGoodMaterial
} from '@/apis/authas'
import EnsureFootBtn from '@/components/EnsureFootBtn'
import appController, { isApp } from '@/controllers/app-android'
import debounce from '@/utils/debounce'

class AsyncTask {
  constructor(tasks, fn) {
    this.tasks = tasks
    this.fn = fn
    this.resolve = null
  }
  async run() {
    const task = this.tasks.pop()
    if (task !== undefined) {
      await this.fn(task)
      this.run()
    } else {
      this.resolve()
    }
  }
  start() {
    return new Promise(resolve => {
      this.resolve = resolve
      this.run()
    })
  }
}
export default {
  name: 'EsLossMaterialView',
  mixins: [basePage],
  config: {
    title: '维修-录入好件',
    fetchDataFn: 'initView'
  },
  components: {
    MaterialEditList,
    VanButton: Button,
    VanSticky: Sticky,
    VanPopup: Popup,
    VanField: Field,
    EnsureFootBtn
  },
  data() {
    return {
      isApp,
      guid: '',
      isLoading: true,
      showInputPopup: false,
      curSn: '',
      list: [],
      query: null,
      resultsMap: {},
      lastEditCodeMap: {},
      batchCodes: []
    }
  },
  watch: {
    resultsMap: {
      deep: true,
      handler() {
        if (!this.startWatchList) return
        if (this.batchCodes.length === 0) return
        this.sysBatchCount()
      }
    }
  },
  methods: {
    gotoSelectCount() {
      this.$router.push({
        path: '/ensure-loss-material/select-count',
        query: this.query
      })
    },
    async toScanMaterial() {
      const { url } = checkAuthCode()
      const sns = await appController.toScanMaterial({
        title: '请扫描物料二维码',
        url,
        params: this.query,
        paramsKey: 'serial_number'
      })
      await this.submitSelectSn(sns.map(item => item.sn))
      this.initView()
    },
    checkSn(sn) {
      return new Promise(resolve => {
        let toast = this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0
        })
        const done = () => {
          toast.close()
          resolve()
        }
        this.addSn(sn.trim())
          .then(done)
          .catch(error => {
            this.$toast.fail({
              message: error.message || error.msg,
              onClose: done
            })
          })
      })
    },
    async onSubmitSn() {
      this.showInputPopup = false
      const sn = this.curSn
      this.curSn = ''
      const arrSn = sn.split(/\s/)
      for (let index = 0; index < arrSn.length; index++)
        await this.checkSn(arrSn[index])
      this.initView()
    },
    async onSnDelete(code, sn) {
      window.console.log(code, sn, this.resultsMap[code])
      await this.$_submitDataWidthUi(async () => {
        await this.$_request(
          editSingleMaterial({
            ...this.query,
            sn,
            type: 'delete'
          })
        )
      }, '确定要移除序列号:' + sn + '?')
      const index = this.resultsMap[code].indexOf(sn)
      this.resultsMap[code].splice(index, 1)
    },
    // 同步批量的数据
    sysBatchCount: debounce(async function() {
      await this.updateBatchCount(this.list)
      this.initView()
    }, 500),
    // 提交选中的批量物料
    async updateBatchCount() {
      const hasChange = []
      this.batchCodes.forEach(code => {
        if (this.resultsMap[code] !== this.lastEditCodeMap[code]) {
          hasChange.push({
            code,
            count: this.resultsMap[code]
          })
          this.lastEditCodeMap[code] = this.resultsMap[code]
        }
      })
      if (hasChange.length) {
        const asyncTaks = new AsyncTask(hasChange, item => {
          return this.$_syncSubmitData(this.addCount(item))
        })
        await asyncTaks.start()
      }
    },
    async addCount({ code, count }) {
      const params = {
        ...this.query,
        code,
        count
      }
      await this.$_request(editBatchMaterial(params))
    },
    async addSn(sn) {
      const params = {
        ...this.query,
        sn,
        type: 'add'
      }
      await this.$_request(editSingleMaterial(params))
    },
    // 挑选出选中的sn
    getSelectedSn(list) {
      return list.reduce((result, item) => {
        result.push(...item.serial_numbers)
        return result
      }, [])
    },
    // 提交选中的序列号
    async submitSelectSn(selectedSns) {
      // 过滤出已经录入的
      selectedSns = selectedSns.filter(
        sn => !this.list.find(item => item.serial_numbers.indexOf(sn) > -1)
      )
      const asyncTaks = new AsyncTask(selectedSns, sn => {
        return this.$_syncSubmitData(this.addSn(sn))
      })
      await asyncTaks.start()
    },
    async submitData() {
      const { href } = await this.$_request(submitInputGoodMaterial(this.query))
      this.$router.go(-1)
      setTimeout(() => {
        href && this.$_handleCfgAction({ href })
      }, 300)
    },
    async onSubmit() {
      this.$_submitDataWidthUi(
        () => this.submitData(),
        '确认完成物料录入，生成报价单？',
        '确认',
        true
      )
    },
    initResult(materials) {
      let results = {}
      const batchCodes = []
      materials.forEach(item => {
        item.is_single = item.type === 'single'
        item.showAll = true
        item.items = item.code_list
        item.hasCount = item.count
        item.price = ''
        item.items.forEach(mItem => {
          mItem.serial_numbers = mItem.sn
          if (item.is_single) {
            results[mItem.code] = mItem.serial_numbers
          } else {
            results[mItem.code] = mItem.count
            batchCodes.push(mItem.code)
          }
        })
      })
      this.batchCodes = batchCodes
      this.resultsMap = results
      this.lastEditCodeMap = JSON.parse(JSON.stringify(results))
      window.console.log('results', results)
      return materials
    },
    async initView() {
      try {
        this.startWatchList = false
        this.query = this.$route.query
        const { list } = await this.$_fetchDataWidthUi(
          getInputGoodMaterialInfoList({
            ...this.query
          })
        )
        window.console.log('initResult', list)
        this.list = this.initResult(list)
        window.console.log('initResult:list', list)
        setTimeout(() => {
          this.startWatchList = true
        }, 1000)
        this.isLoading = false
      } catch (error) {
        window.console.log('initResult:error', error)
        this.isLoading = false
      }
    }
  }
}
</script>
<style lang="scss">
.es-loss-material {
  padding-bottom: 80px;
  .page-head {
    padding: 10px 15px;
    justify-content: space-between;
    background-color: $default-page-color;
  }
  .empty-box {
    height: 50vh;
    justify-content: center;
    align-items: center;
    .iconfont {
      font-size: 80px;
      margin-bottom: 16px;
    }
  }
}
</style>
